<template>
  <v-dialog max-width="600" scrollable v-model="dialog" persistent>
    <template v-slot:activator="{ on: menu }">
      <v-btn class="ml-2" color="gifty" text v-on="{ ...menu }">
        <v-icon>mdi-plus</v-icon>
        Insérer des Vouchers Refill
      </v-btn>
    </template>
    <v-card>
      <v-progress-linear v-if="isLoading" color="primary" indeterminate reverse></v-progress-linear>
      <v-card-title>
        <v-icon left>mdi-tune</v-icon>
        Insértion d'une série de Vouchers (Refill)
        <v-spacer />
        <v-btn small outlined text class="mr-2 ml-2 no-anim" @click="clearForm">Effacer
        </v-btn>
        <v-icon @click="close">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-5">
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <v-img max-height="80" max-width="80" :src="require('@/assets/voucher.png')"></v-img>
          </v-col>

          <v-col cols="12" v-if="error">
            <v-alert border="left" color="error" dark>
              {{ error }}

            </v-alert>
          </v-col>

          <v-col cols="12">
            <v-select prepend-icon="mdi-filter-outline" chips deletable-chips small-chips dense clearable
              :items="services" v-model="form.service_id" :item-text="'name'" :item-value="'id'" label="Service..."
              @input="
                errors && errors.service_id
                  ? (errors.service_id = '')
                  : false
                " :error-messages="errors.service_id">
              <template v-slot:item="slotProps">
                <div style="width: 50px">
                  <img :src="getImageLink(slotProps.item.image)" max-height="25" max-width="25" width="25" height="25"
                    class="p-2" />
                </div>

                {{ slotProps.item.name }}
              </template>
            </v-select>

            <v-switch v-model="form.is_excel_file" dense label="Importer un fichier excel" hide-details="" />
          </v-col>
          <v-col cols="12" v-show="form.is_excel_file">
            <v-file-input label="Fichier *" accept=".xls, .xlsx" show-size suffix="Excel"
              @change="($event) => form.excel_file = $event" @focus="
                errors && errors.excel_file
                  ? (errors.is_excel_file = '')
                  : false
                " ref="excel_file" chips :error-messages="errors.excel_file" />
          </v-col>

          <v-col cols="12" v-show="!form.is_excel_file">
            <v-text-field v-model="form.quantity" prepend-icon="mdi-ticket-percent-outline" type="text" :min="1" dense
              clearable label="Quantité.." @input="
                errors && errors.quantity
                  ? (errors.quantity = '')
                  : false
                " :error-messages="errors.quantity"></v-text-field>
          </v-col>

          <v-col cols="12" v-show="!form.is_excel_file">
            <v-text-field v-model="form.amount" prepend-icon="mdi-currency-usd " type="number" dense clearable
              label="Montant.." @input="
                errors && errors.amount
                  ? (errors.amount = '')
                  : false
                " :error-messages="errors.amount"></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field v-model="form.expiration_date" prepend-icon="mdi-calendar" append-icon="mdi-calendar"
              type="date" dense clearable label="Date d’expiration.." @input="
                errors && errors.expiration_date
                  ? (errors.expiration_date = '')
                  : false
                " :error-messages="errors.expiration_date">
            </v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field v-model="form.message" prepend-icon="mdi-message-badge" type="text" dense clearable
              label="Message.." @input="
                errors && errors.message
                  ? (errors.message = '')
                  : false
                " :error-messages="errors.message">
            </v-text-field>
          </v-col>

          <v-col cols="12">
            <div class="d-flex align-center">
              <v-file-input prepend-icon="mdi-tooltip-image-outline" accept="image/png, image/jpeg, image/jpg, .svg, .gif"
                label="Image " show-size suffix="Image" @change="($event) => form.image = $event" @focus="
                  errors && errors.image
                    ? (errors.image = '')
                    : false
                  " ref="refill_image_file" chips :error-messages="errors.image" />

              <v-btn depressed icon small class="primary white--text ml-2" v-if="form.image"
                @click="previewImage(form.image)">
                <v-icon small>mdi mdi-eye</v-icon>
              </v-btn>
            </div>

          </v-col>

          <v-col cols="12">
            <v-file-input label="Vidéo " prepend-icon="mdi-movie-open-play" show-size suffix="Vidéo"
              @change="($event) => form.video = $event" @focus="errors && errors.video
                ? (errors.video = '')
                : false" ref="refill_video_file" chips :error-messages="errors.video" />
          </v-col>

          <v-col>
            <v-select prepend-icon="mdi-wallet-membership" chips deletable-chips small-chips dense clearable
              :loading="occasionCardsLoading" :items="occasion_cards" v-model="form.occasion_card_id" 
              :item-text="'name'"
              :item-value="'id'" label="Carte d'occasion..." 
              @input="
                errors && errors.occasion_card_id
                  ? (errors.occasion_card_id = '')
                  : false
                " :error-messages="errors.occasion_card_id">

              <template v-slot:selection="{ item }">
                <div style="width: 100px">
                  <img :src="previewSelectCard(item)" max-height="100" max-width="100" width="100" height="100"
                    class="p-2" />
                </div>
              </template>
              <template v-slot:item="slotProps">
                <div style="width: 100px">
                  <img :src="getImageLink(slotProps.item.image)" max-height="100" max-width="100" width="100" height="100"
                    class="p-2" />
                </div>
              </template>
            </v-select>
          </v-col>

        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" :disabled="isDisabled" depressed block @click="handleStore">
          Sauvegarder
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { HTTP } from "@/http-common";

export default {
  data() {
    return {
      services: [],
      dialog: false,
      form: {
        quantity: 1,
        supplier_id: null,
        service_id: null,
        amount: null,
        expiration_date: null,
        is_excel_file: false,
        excel_file: null,

        message: '',
        image: '',
        video: '',
        occasion_card_id: ''
      },
      isLoading: false,
      error: "",
      errors: [],

      // Occasion cards
      occasion_cards: [],
      occasionCardsLoading: false
    };
  },
  methods: {
    getImageLink(file) {
      return process.env.VUE_APP_FILE_URL + file;
    },
    handleStore() {
      //this.dialog = false
      //this.$emit('filter', this.filter)
      this.isLoading = true;
      this.errors = [];
      this.error = "";

      var formData = new FormData();
      Object.keys(this.form).forEach((key) => {
        if (key == "is_excel_file") {
          formData.append(key, this.form.is_excel_file ? "true" : "false");
        } else {
          formData.append(key, this.form[key]);
        }

      });

      HTTP.post("/manager/vouchers/create/refill", formData, { params: {} })
        .then((res) => {
          console.log(res.data);

          this.isLoading = false;

          this.$emit("refresh");
          this.$store.dispatch("fetchAccount");
          this.close();

        })
        .catch((err) => {
          this.isLoading = false;

          this.error = err.response.data.message;
          this.errors = err.response.data.errors;
        });
    },
    clearForm() {
      this.form = {};
    },
    close() {
      this.$refs.excel_file.reset();
      this.$refs.refill_video_file.reset();
      this.$refs.refill_image_file.reset();

      this.form = {
        quantity: 1,
        supplier_id: null,
        service_id: null,
        amount: null,
        expiration_date: null,
        is_excel_file: false,
        excel_file: null,

        message: '',
        image: '',
        video: '',
        youtube_link: '',
        occasion_card_id: ''
      },
        this.dialog = false;

    },
    getServices() {
      HTTP.get("/service/refill", { params: {} })
        .then((res) => {
          this.services = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getOccasionCards() {
      this.occasionCardsLoading = true;
      HTTP.get("/v1/mobile/occasion_cards", { params: {} })
        .then((res) => {
          this.occasion_cards = res.data.data;
          this.occasionCardsLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.occasionCardsLoading = false;
        });
    },
    previewSelectCard(item){
    
      if(item){
          console.log(this.getImageLink(item.thumbnail));
        
        return this.getImageLink(item.thumbnail);
      }

      return null;
      
    }
  },
  computed: {
    isDisabled() {
      return (
        Object.values(this.form).every(
          (x) => x === null || x === "" || x.length === 0
        ) || this.isLoading
      );
    },

    
  },
  created() {
    this.getServices();

    this.getOccasionCards();
  },
};
</script>

<style scoped></style>

<style scoped>
.no-anim:hover {
  transform: none;
}
</style>